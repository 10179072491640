import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormSection from './ui-kit/FormSection';
import Input from './ui-kit/Input';
import Button from './ui-kit/Button';
import Message from './ui-kit/Message';
import _ from 'lodash';
import {
  completeCurrentStage,
  getFormSectionProps,
  resetStage,
  isCurrentStage,
} from '../../ducks/stepper';
import { isLoggedIn, isEmailValid } from '../../ducks/user';
import { validateEmail } from '../../actions/user';
import Link from './ui-kit/Link';

class Email extends Component {
  state = {
    email: '',
    validEmail: false,
    inProgress: false,
    showFailure: false,
  };

  componentDidMount() {
    const { onLoad } = this.props;
    if (onLoad) {
      onLoad();
    }
  }

  shouldValidate = (prevProps, prevState) => {
    const { inProgress: prevInProgress } = prevState;
    const { inProgress } = this.state;
    return !prevInProgress && inProgress;
  };

  shouldCompleteStage = () => {
    const isExistingEmailError = this.isExistingEmailError();
    const { inProgress } = this.state;
    const { user } = this.props;
    const { stepper, stage } = this.props;
    return (
      inProgress &&
      isEmailValid(user) &&
      !isExistingEmailError &&
      isCurrentStage(stepper, stage)
    );
  };

  shouldShowError = () => {
    const isExistingEmailError = this.isExistingEmailError();
    const { inProgress } = this.state;
    return inProgress && isExistingEmailError;
  };

  isExistingEmailError = () => {
    return this.isExistingEmail() && this.props.errorOnExistingEmail;
  };

  isExistingEmail = () => {
    const { user } = this.props;
    const { isEmailRegistered } = user.validateEmail || {};
    return isEmailRegistered;
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.shouldValidate(prevProps, prevState)) {
      this.setState({ showFailure: false });
      const { validEmail, email } = this.state;
      const { onSubmit, dispatch } = this.props;
      if (onSubmit) {
        onSubmit({ email: validEmail });
      }
      dispatch(validateEmail(email));
      return;
    }
    if (this.shouldCompleteStage()) {
      this.setState({ inProgress: false });
      const { user, dispatch } = this.props;
      const { isEmailRegistered, email } = _.get(user, `validateEmail`, {});
      dispatch(
        completeCurrentStage({
          data: {
            isEmailRegistered,
            email,
          },
        }),
      );
      return;
    }
    if (this.shouldShowError()) {
      this.setState({
        inProgress: false,
        showFailure: true,
      });
      return;
    }
  }

  emailChange = (value) => {
    this.setState({ email: _.toLower(value) });
  };

  handleOnEditClick = () => {
    const { stage, dispatch } = this.props;
    this.setState({ inProgress: false });
    dispatch(resetStage({ stage }));
  };

  validateEmail(email) {
    const validEmailReg = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    const validEmail = !_.isEmpty(email) && validEmailReg.test(email);
    this.setState({ validEmail });
    return validEmail;
  }

  renderError() {
    const { showFailure } = this.state;
    const message = this.isExistingEmailError() ? (
      <p>
        You already have an account with Optus Sport. Please{' '}
        <Link
          link={
            this.props.existingEmailRoute
              ? this.props.existingEmailRoute
              : '/login'
          }
          target="_self"
          textSize="text-xs"
        >
          login
        </Link>{' '}
        to continue.
      </p>
    ) : (
      'Sorry, something is wrong with validating this email!'
    );
    return (
      showFailure && (
        <div className="mt-10 mb-10">
          <Message
            type="failure"
            className="mt-8 mb-16"
            message={message}
            open
          />
        </div>
      )
    );
  }

  render() {
    const { validEmail, email, inProgress } = this.state;
    const { hideEditEmailLink } = this.props;

    const { stepper, user, stage } = this.props;
    const defaultValue = _.get(stepper, `data.email`) || ``;
    const shouldRender = !isLoggedIn(user);
    // Workaround: FormSection disables the Edit link if the form is disabled.
    const { disable, ...formSectionProps } = getFormSectionProps(
      stepper,
      stage,
    );
    return (
      shouldRender && (
        <FormSection
          label={this.props.label}
          onEditClick={this.handleOnEditClick}
          hideEdit={hideEditEmailLink}
          {...formSectionProps}
        >
          <Input
            autoFocus={!defaultValue}
            defaultValue={email || defaultValue || ``}
            className="leading-1.14 md:w-360 xl:w-381"
            id="email"
            type="email"
            labelText="Email *"
            placeHolder="e.g John@email.com"
            changed={this.emailChange}
            validate
            validator={(value, e) => {
              return _.isEmpty(value) || !this.validateEmail(value)
                ? 'Invalid email provided.'
                : null;
            }}
            marginBottom="8"
          />
          {this.props.renderInfo && this.props.renderInfo()}
          {this.renderError()}
          <div
            className={`${
              validEmail ? 'pointer-events-auto' : 'pointer-events-none'
            } ${inProgress ? `cursor-not-allowed` : ``}`}
          >
            <Button
              disabled={inProgress}
              loading={inProgress}
              text="Next"
              type="primary-alternate"
              handler={() => this.setState({ inProgress: true })}
              width="w-full mt-24 mb-16 md:w-266 xl:w-276"
              rounded
            />
          </div>
        </FormSection>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    stepper: state.stepper,
  };
}

export default connect(mapStateToProps)(Email);
