import React from 'react';
import HeaderNav from '../ui-kit/HeaderNav/';
import LogoIcon from '../ui-kit/Icons/LogoIcon';

const VoucherFormContainer = ({ children, ...props }) => {
  const redirectToHome = () => {
    window.open('/', '_self');
    return;
  };
  return (
    <>
      <HeaderNav
        simpleHeader
        showBack
        onBackClicked={redirectToHome}
        backLabel='Home'
        oneThirdWidth
        customClassNames={'z-300'}
      >
        <div
          className='flex w-1/3 justify-center cursor-pointer'
          onClick={redirectToHome}
        >
          <LogoIcon />
        </div>
        <div className='flex w-1/3' />
      </HeaderNav>
      <div
        className={`min-h-page flex w-full flex-col ${props.classNames &&
          props.classNames}`}
      >
        <div className='flex justify-center w-full z-100'>{children}</div>
      </div>
    </>
  );
};

export default VoucherFormContainer;
