import React from 'react';
import PropTypes from 'prop-types';

const InfoIcon2 = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 3a9 9 0 1 1 0 18 9 9 0 0 1 0-18zm-.938 5.255c0-.531.407-.943.938-.943.526 0 .938.412.938.943a.922.922 0 0 1-.938.932.92.92 0 0 1-.938-.932zm.191 2.245h1.5v6h-1.5v-6z'
      fill={color}
    />
  </svg>
);

InfoIcon2.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

InfoIcon2.defaultProps = {
  width: 24,
  height: 24,
  color: '#FDCC08',
};

export default InfoIcon2;
