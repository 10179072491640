import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as userAction from '../../actions/user';
import _ from 'lodash';
import LoginForm from '../ui/LoginForm';

const ConnectedLogin = (props) => {
  const initObserver = _.get(props, 'initObserver');
  const loggedIn = _.get(props, 'loggedIn');
  const inProgress = _.get(props, 'inProgress');
  const loginFailure = _.get(props, 'loginFailure');
  const actions = _.get(props, 'userActions');

  // Only pass actions required
  const actionUnsetLoginUser = _.get(actions, 'loginUser');
  const actionToggleAuthInProgress = _.get(actions, 'toggleAuthInProgress');

  return (
    <LoginForm
      initObserver={initObserver}
      loggedIn={loggedIn}
      inProgress={inProgress}
      loginFailure={loginFailure}
      loginUser={actionUnsetLoginUser}
      toggleAuthInProgress={actionToggleAuthInProgress}
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return _.merge({}, _.get(state, 'user', {}));
};

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedLogin);
