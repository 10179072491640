import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import VerifyCode from './VerifyCode';
import SendVerification from './SendVerification';
import { verificationReset } from '../../ducks/verification';

class VerifyPhoneNumber extends React.Component {
  state = {
    showVerify: false
  };

  onSuccess = () => {
    const { onCodeVerified } = this.props;
    if (onCodeVerified) {
      const { number, parsedNumber } = this.state;
      onCodeVerified({ number, parsedNumber });
    }
  };

  onError = error => {
    const { onError } = this.props;
    if (onError) {
      onError(error);
    }
  };

  onPhoneNumberVerified = ({ number, parsedNumber, token }) => {
    this.setState({ showVerify: true, number, parsedNumber, token });
    const { onPhoneNumberVerified } = this.props;
    if (onPhoneNumberVerified) {
      onPhoneNumberVerified({ number, token });
    }
  };

  onPhoneNumberReset = () => {
    this.setState({ showVerify: false, token: null });
    const { onPhoneNumberReset } = this.props;
    if (onPhoneNumberReset) {
      onPhoneNumberReset();
    }
  };

  componentDidMount() {
    this.props.verificationReset();
  }

  render() {
    const {
      email,
      defaultValue,
      inProgress,
      verification,
      autoFocus
    } = this.props;
    const { showVerify, number, token } = this.state;
    const verifyCodeData = { email, number, token };
    return (
      <>
        <SendVerification
          autoFocus={autoFocus}
          defaultValue={defaultValue}
          disabled={verification.blocked}
          email={email}
          onReset={this.onPhoneNumberReset}
          onSuccess={this.onPhoneNumberVerified}
          onError={this.onError}
        />
        <VerifyCode
          disabled={verification.blocked}
          show={showVerify}
          data={verifyCodeData}
          onSuccess={this.onSuccess}
          onError={this.onError}
          inProgress={inProgress}
        />
      </>
    );
  }
}

VerifyPhoneNumber.propTypes = {
  autoFocus: PropTypes.bool,
  email: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  inProgress: PropTypes.bool,
  onCodeVerified: PropTypes.func.isRequired,
  onPhoneNumberVerified: PropTypes.func,
  onPhoneNumberReset: PropTypes.func,
  onError: PropTypes.func
};

function mapStateToProps(state) {
  return {
    verification: state.verification
  };
}

const mapDispatchToProps = {
  verificationReset
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPhoneNumber);
