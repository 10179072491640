import React from 'react';
import get from 'lodash/get';
import HeaderNav from './ui-kit/HeaderNav/';
import LogoIcon from './ui-kit/Icons/LogoIcon';
import { Redirect, withRouter } from 'react-router-dom';

class SimpleFormContainer extends React.Component {
  state = {};

  onBackClicked = (e) => {
    if (this.props.onBackClicked) {
      this.props.onBackClicked();
      return;
    }
    if (this.props.redirect) {
      this.setState({ redirect: true });
      return;
    }
    const locationState = get(this.props, `history.location.state`);
    if (locationState) {
      this.props.history.goBack();
      return;
    }
    window.open('/', '_self');
    return;
  };

  redirectToHome = (e) => {
    if (this.props.redirectToHHome) {
      this.props.redirectToHome();
      return;
    }

    window.open('/', '_self');
    return;
  };

  render() {
    const { isShowBack = true } = this.props;
    if (this.state.redirect) {
      return <Redirect to={this.props.redirect} />;
    }
    const marginTop = this.props.useOwnPageHeader
      ? `mt-32 md:mt-40 xl:mt-96`
      : `mt-64 md:mt-80 xl:mt-96`;
    return (
      <div className='w-full h-full'>
        {this.props.useOwnPageHeader && (
          <HeaderNav
            simpleHeader
            showBack={isShowBack}
            onBackClicked={this.onBackClicked}
            backLabel={this.props.backLabel || `Back`}
            oneThirdWidth
            customClassNames={'z-300'}
          >
            <div
              className='flex w-1/3 justify-center cursor-pointer'
              onClick={this.redirectToHome}
            >
              <LogoIcon />
            </div>
            <div className='flex w-1/3' />
          </HeaderNav>
        )}
        <div
          className={`flex w-full flex-col ${marginTop} ${this.props
            .className || ''}`}
        >
          <div className='flex justify-center w-full z-100 h-max-content'>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

SimpleFormContainer.defaultProps = {
  useOwnPageHeader: true,
};

export default withRouter(SimpleFormContainer);
