import React, { useState, useEffect, useRef } from 'react';
import { get, isEmpty } from 'lodash';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { analyticAction } from '../../ducks/analytic';

import usePrevious from '../../utils/hooks/usePrevious';
import H3 from '../ui/ui-kit/Typography/H3';
import Input from '../ui/ui-kit/Input';
import Button from '../ui/ui-kit/Button';
import Message from '../ui/ui-kit/Message';
import LoadingIcon from '../ui/ui-kit/Icons/LoadingIcon';

const validateEmail = (value) => {
  const validEmailReg = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
  return !isEmpty(value) && validEmailReg.test(value);
};

const SignUpFooter = ({ appleTvLink, referrer, history }) => (
  <span
    className={`
      block
      text-center
      text-xs
    `}
  >
    Don't have an account?{' '}
    <a
      className={`
        font-MarkProBold
        leading-lg
        no-underline
        text-teal
        hover:text-teal-dark
        active:text-teal-light
        os-transition-btn
      `}
      href={
        appleTvLink && appleTvLink.length > 0
          ? appleTvLink
          : '/register'
      }
      target="_blank"
      rel="noopener noreferrer"
      onClick={(e) => {
        e.preventDefault();
        if (appleTvLink && appleTvLink.length > 0) {
          history.push({ pathname: `/${appleTvLink}`, state: { referrer } });
        } else {
          // 23rd Apri 2021: Current bug of global header/footer remains behind
          // history.push({ pathname: '/signup', state: { referrer } });
          window.open('/register', '_self');
        }
      }}
    >
      <span>Sign up</span>
    </a>
  </span>
);

const LoginForm = (props) => {
  const {
    inProgress,
    onMount,
    onSubmit,
    toggleAuthInProgress,
    loginUser,
    onError,
    bannerMessage,
    disableEmail,
    showSignup,
    initObserver,
    loginFailure,
    email,
    appleTvLink,
    ...rest
  } = props;

  const prevState = usePrevious(inProgress || null);
  // Hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const emailEl = useRef();
  const passwordEl = useRef();

  const [state, setState] = useState({
    inProgress: false,
    errors: {},
  });

  // Check the inProgress state on props
  useEffect(() => {
    if (inProgress !== prevState) {
      setState((state) => ({
        ...state,
        inProgress: false,
      }));
    }
    // eslint-disable-next-line
  }, [inProgress]);

  useEffect(() => {
    if (onMount) {
      onMount();
    }
  }, [onMount]);

  const getErrorMessage = (stateErrors, resultError) => {
    let message = {
      title: null,
      type: null,
      text: null,
    };

    // Errors
    if (stateErrors || resultError) {
      message.type = 'failure';

      const emailError = get(stateErrors, 'email');
      const passwordError = get(stateErrors, 'password');

      if (emailError || passwordError) {
        if (emailError && passwordError) {
          message.text = 'You must enter a valid email and password.';
        } else {
          message.text = `${emailError || ''} ${passwordError || ''}`;
        }
      } else {
        // Option: Default resultError to static text instead of error messaging from api
        // i.e. Please check your login credentials and try again.
        message.text = resultError;
      }
    }

    return message;
  };

  const onLogin = (e) => {
    e.preventDefault();
    // Application has no info on user
    dispatch(
      analyticAction({
        eventName: 'login_button_clicked',
        level: 'info',
      }),
    );
    if (onSubmit) {
      onSubmit();
    }
    if (state.inProgress) {
      return false;
    } else {
      // Dispatch action to lock auth process
      setState((state) => ({ ...state, inProgress: true }));
      if (toggleAuthInProgress) {
        toggleAuthInProgress(true);
      }
    }
    // Check if all mandatory fields have been filled in
    let stateChangeObj = {};
    let emailInput = get(emailEl.current, ['state', 'value']),
      passwordInput = get(passwordEl.current, ['state', 'value']);

    const validEmail = validateEmail(emailInput);

    if (validEmail && !isEmpty(passwordInput)) {
      // Dispatch action to login here
      loginUser({
        email: emailInput,
        password: passwordInput,
      });
      // Clear any existing errors
      setState((state) => ({
        ...state,
        errors: {},
      }));
    } else {
      if (!validEmail || isEmpty(passwordInput)) {
        setState((state) => ({
          ...state,
          errors: {},
        }));

        if (!validEmail) {
          const errors = {
            email: 'Please enter a valid email.',
          };
          setState((state) => ({
            ...state,
            errors,
          }));
        }
        if (isEmpty(passwordInput)) {
          const errors = {
            password: 'Please enter a valid password.',
          };
          setState((state) => ({
            ...state,
            errors,
          }));
          if (onError) {
            onError();
          }
        }
      }
      // Dispatch action to unlock auth process
      setState((state) => ({ ...state, inProgress: false }));
      toggleAuthInProgress(false);
    }

    if (!isEmpty(stateChangeObj)) {
      setState(stateChangeObj);
    }
  };

  if (!initObserver) {
    return (
      <div
        className={`
            page
            login
            mt-80
            mx-auto
            px-14
            w-269
            xs:w-350
            md:mt-144
            md:w-369
            lg:w-381
          `}
      >
        <LoadingIcon
          className={`
              block
              m-auto
              text-center
              lg:h-64
              lg:w-64
            `}
        />
      </div>
    );
  }

  const loggedIn = get(rest, 'loggedIn');
  const redirectTo = location?.state?.redirectTo;
  const referrer = get(rest, 'referrer') || location?.state?.referrer;
  const to = redirectTo
    ? {
        pathname: redirectTo,
        state: { referrer },
      }
    : referrer || '/';

  if (loggedIn) {
    if (location?.state?.errorCode && referrer === '/help') {
      const toWithErrorCode = {
        pathname: '/help',
        state: location?.state?.errorCode,
      };
      return <Redirect to={toWithErrorCode} />;
    }
    return <Redirect to={to} />;
  }

  // const loginFailure = get(loginFailure);
  const inProgressLocal = get(state, 'inProgress');

  const stateErrors = get(state, 'errors');
  // Get message details
  const message = getErrorMessage(stateErrors, loginFailure);
  const messageType = get(message, 'type');
  const messageTitle = get(message, 'title');
  const messageText = get(message, 'text');
  const displayMessage = messageType && messageText;

  return (
    <div
      className={`
          page
          login
          mt-80
          mx-auto
          px-14
          w-269
          xs:w-350
          md:mt-144
          md:w-369
          lg:w-381
        `}
    >
      <form>
        <H3
          className={`
              block
              text-center
              md:text-3xl
              lg:text-4xl
            `}
        >
          Login
        </H3>
        {displayMessage ? (
          <Message
            className={`
                  mb-16
                `}
            type={messageType}
            open={true}
            title={messageTitle}
            message={messageText}
            showIcon={true}
            showClose={false}
          />
        ) : null}
        {bannerMessage && bannerMessage()}
        <Input
          type="string"
          disabled={
            inProgressLocal || messageType === 'success' || disableEmail
          }
          id="login-email"
          placeHolder="Enter your email"
          labelText="Email"
          validate={true}
          validator={(value, e) => {
            return isEmpty(value) || !validateEmail(value)
              ? 'Invalid email provided.'
              : null;
          }}
          ref={emailEl}
          value={email}
          showLockedIcon={disableEmail}
        />
        <Input
          type="password"
          disabled={inProgressLocal || messageType === 'success'}
          id="login-pwd"
          name="login-pwd"
          placeHolder="Enter your password"
          labelText="Password"
          validate={true}
          ref={passwordEl}
          validator={(value, e) => {
            return isEmpty(value) ? 'No password provided.' : '';
          }}
        />

        <div
          className={`
              mt-8
              mb-24
            `}
        >
          <Button
            type="submit"
            disabled={inProgress || messageType === 'success'}
            loading={inProgress}
            handler={onLogin}
            text="Log in"
            width="w-full"
          />
        </div>
        <div
          className={`
              pb-24
            `}
        >
          <span
            className={`
                block
                text-center
                text-xs
              `}
          >
            <Link
              to="/forgotpassword"
              className={`
                  font-MarkProBold
                  leading-lg
                  no-underline
                  text-teal
                  hover:text-teal-dark
                  active:text-teal-light
                  os-transition-btn
                `}
            >
              Reset or create your password.
            </Link>
          </span>
        </div>
        <div
          className={`
              pb-24
              ${showSignup ? 'login-bottom' : ''}
            `}
        >
          <span
            className={`
                block
                text-center
                text-xs
              `}
          >
            <a
              className={`
                  font-MarkProBold
                  leading-lg
                  no-underline
                  text-teal
                  hover:text-teal-dark
                  active:text-teal-light
                  os-transition-btn
                `}
              href='https://www.optus.com.au/for-you/support/answer?requestType=NormalRequest&id=7040&source=5&question=Optus%20Sport%20username%20&%20password%20help'
              target='_blank'
              rel='noopener noreferrer'
            >
              Get help finding your username.
            </a>
          </span>
        </div>

        <div
          className={`
            mt-24
          `}
        >
          {showSignup && (
            <SignUpFooter
              appleTvLink={appleTvLink}
              referrer={referrer}
              history={history}
            />
          )}
        </div>
      </form>
    </div>
  );
};

LoginForm.defaultProps = {
  showBorderBottom: true,
  showSignup: true,
  disableEmail: false,
};

export default LoginForm;
