import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  startVerificationSession,
  STAGE_SESSION_START,
  STAGE_VERIFY_MOBILE
} from '../../ducks/verification';
import PhoneNumberInput from './PhoneNumberInput';
import Button from './ui-kit/Button';
import Message from './ui-kit/Message';

class SendVerification extends React.Component {
  state = {
    showSend: true,
    showError: false,
    errorMessage: ``
  };

  shouldStartVerification = (prevProps, prevState) => {
    const { inProgress: prevInProgress } = prevState;
    const { inProgress, isValid } = this.state;
    const hasTransitionedToInProgress = !prevInProgress && inProgress;
    return isValid && hasTransitionedToInProgress;
  };

  hasVerificationChanged = prevProps => {
    const { verification: currVerification } = this.props;
    const { verification: prevVerification } = prevProps;
    return !_.isEqual(currVerification, prevVerification);
  };

  shouldShowError = prevProps => {
    const { showError } = this.state;
    const { verification } = this.props;
    const { error: currError, stage } = verification;
    const { error: prevError } = prevProps.verification;
    const hasTransitionedToError = !prevError && currError;
    const isCurrentStage =
      stage === STAGE_SESSION_START || stage === STAGE_VERIFY_MOBILE;
    return !showError && hasTransitionedToError && isCurrentStage;
  };

  shouldSucceedVerification = prevProps => {
    const { isValid } = this.state;
    const { verification } = this.props;
    const { token: prevToken } = prevProps.verification;
    const { token } = verification;
    const hasTransitionedToSuccess = !prevToken && token;
    return isValid && hasTransitionedToSuccess;
  };

  onSuccess = ({ number, token }) => {
    this.setState({
      showSend: false,
      showError: false,
      errorMessage: ``,
      inProgress: false
    });
    const { onSuccess } = this.props;
    if (onSuccess) {
      const { parsedNumber } = this.state;
      onSuccess({ number, parsedNumber, token });
    }
  };

  onError = () => {
    const { verification } = this.props;
    const { error, errorCode } = verification;
    this.setState({
      showSend: true,
      showError: true,
      errorMessage: error,
      inProgress: false
    });
    const { onError } = this.props;
    if (onError) {
      onError(errorCode);
    }
  };

  onReset = () => {
    const { shouldResetOnError, onReset } = this.props;
    if (shouldResetOnError && onReset) {
      onReset();
    }
  };

  componentDidMount() {
    const { showError } = this.state;
    const { verification } = this.props;
    const { error } = verification;
    const isInErrorState = !showError && verification.blocked;
    if (isInErrorState) {
      this.setState({ showError: true, errorMessage: error });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.shouldStartVerification(prevProps, prevState)) {
      this.setState({
        showError: false,
        errorMessage: ``
      });
      const { email } = this.props;
      const { number } = this.state;
      this.props.startVerificationSession({ email, number });
      return;
    }
    if (this.shouldShowError(prevProps)) {
      this.onReset();
      this.onError();
      return;
    }
    if (this.shouldSucceedVerification(prevProps, prevState)) {
      const { verification } = this.props;
      const { token } = verification;
      const { number } = this.state;
      this.onSuccess({ number, token });
      return;
    }
  }

  render() {
    const { onReset, disabled, defaultValue, autoFocus } = this.props;
    const {
      showSend,
      isValid,
      showError,
      errorMessage,
      inProgress
    } = this.state;
    return (
      <>
        <PhoneNumberInput
          autoFocus={autoFocus}
          defaultValue={defaultValue}
          disabled={disabled}
          onValidate={({ number, parsedNumber, isValid }) => {
            this.setState({
              number,
              parsedNumber,
              isValid,
              showSend: true,
              showError: false
            });
            if (onReset) {
              onReset();
            }
            if (!isValid) {
              return `This isn't a valid Australian mobile number.`;
            }
          }}
        />
        {showError && (
          <Message
            className="mb-16"
            type="failure"
            open
            message={errorMessage}
            showIcon
          />
        )}
        {showSend && (
          <Button
            disabled={disabled}
            className={`${
              isValid ? 'pointer-events-auto' : 'pointer-events-none'
            }`}
            text="Send my verification code"
            type="primary-alternate"
            width="w-full mb-16 md:w-266 xl:w-276"
            loading={inProgress}
            handler={() => this.setState({ inProgress: true })}
            rounded
          />
        )}
      </>
    );
  }
}

SendVerification.propTypes = {
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  email: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func,
  onReset: PropTypes.func,
  shouldResetOnError: PropTypes.bool
};

SendVerification.defaultProps = {
  shouldResetOnError: true
};

function mapStateToProps(state) {
  return {
    verification: state.verification
  };
}

const mapDispatchToProps = {
  startVerificationSession
};

export default connect(mapStateToProps, mapDispatchToProps)(SendVerification);
