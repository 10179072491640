import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectors, useSelector } from '../../store/createPortalStore';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FormSection from './ui-kit/FormSection';
import VerifyPhoneNumber from './VerifyPhoneNumber';
import {
  completeCurrentStage,
  resetStage,
  getFormSectionProps
} from '../../ducks/stepper';
import defaultConfig from '../../constants/defaultConfig';

const { FEATURE_PHONE_CODE_VERIFICATION } = defaultConfig.features;

const VerifyPhoneNumberForm = props => {
  // Destructuring
  const { user: userSelector, stepper: stepperSelector } = selectors;
  // Selectors
  const user = useSelector(userSelector.user);
  const stepper = useSelector(stepperSelector.stepper);
  // Props
  const { stage, label, onLoad, onSubmit } = props;
  // Hooks
  const dispatch = useDispatch();

  useEffect(() => {
    if (onLoad) {
      onLoad();
    }
    // eslint-disable-next-line
  }, []);

  const onSuccess = ({ number, parsedNumber }) => {
    if (onSubmit) {
      onSubmit();
    }
    dispatch(
      completeCurrentStage({
        data: {
          number,
          nationalNumber: parsedNumber.formatNational()
        }
      })
    );
  };

  const handleOnEditClick = () => {
    dispatch(resetStage({ stage }));
  };

  if (!FEATURE_PHONE_CODE_VERIFICATION) {
    return null;
  }

  const {
    data: { email, number }
  } = stepper;
  const userEmail =
    _.get(user, 'authData.cognitoUser.attributes.email') || email || ``;

  // Workaround: FormSection disables the Edit link if the form is disabled.
  const { disable, ...formSectionProps } = getFormSectionProps(stepper, stage);
  return (
    <FormSection
      label={label}
      onEditClick={handleOnEditClick}
      {...formSectionProps}
    >
      {userEmail && (
        <VerifyPhoneNumber
          autoFocus={!number}
          email={userEmail}
          defaultValue={number}
          onCodeVerified={onSuccess}
        />
      )}
    </FormSection>
  );
};

VerifyPhoneNumberForm.propTypes = {
  onLoad: PropTypes.func,
  onSubmit: PropTypes.func,
  label: PropTypes.string.isRequired
};

export default VerifyPhoneNumberForm;
