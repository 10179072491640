import _ from 'lodash';
import * as types from '../constants/actionTypes';
import API from '@aws-amplify/api';
import pageMapping from '../middleware/pageMapping';
import defaultConfig from 'constants/defaultConfig';

export function loadPage(
  id,
  appManagerKey,
  appManagerVersion,
  getFeatureFlags,
) {
  const customWidgetConfig = getFeatureFlags
    ? getFeatureFlags('CUSTOM_WIDGET')
    : defaultConfig.features?.CUSTOM_WIDGET;

  return function(dispatch) {
    dispatch({ type: types.GET_PAGE_INPROGRESS, page: { id: id } });

    const fetchMetadataPage = () => {
      return API.get('fe-api-dev-metadataGetPage', `/pages/${id}`, {})
        .then((page) => {
          if (page && page.id) {
            dispatch({
              type: types.GET_PAGE_SUCCESS,
              page: pageMapping({ page, id, customWidgetConfig }),
            });
          } else {
            dispatch({ type: types.GET_PAGE_FAILURE, page: { id: id } });
          }
        })
        .catch((error) => {
          dispatch({ type: types.GET_PAGE_FAILURE, page: { id: id } });
        });
    };

    if (appManagerVersion === 'v2' || appManagerVersion === 'v3') {
      const api = `fe-api-dev-appManagerGetPage${
        appManagerVersion === 'v3' ? 'V2' : ''
      }`;
      return API.get(api, `/apps/web/pages/${appManagerKey}`, {})
        .then((page) => {
          const success = _.get(page, 'success');
          const dataPanels = _.get(page, 'data.panels');

          if (success && !_.isEmpty(dataPanels)) {
            dispatch({
              type: types.GET_PAGE_SUCCESS,
              page: pageMapping({ page, id, customWidgetConfig }),
            });
          } else {
            fetchMetadataPage();
          }
        })
        .catch((error) => {
          fetchMetadataPage();
        });
    } else {
      return fetchMetadataPage();
    }
  };
}
