import React from 'react';

const CircularQuestionIcon = ({ width, height, viewBox }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path fill="#888A8C" fillRule="evenodd" d="M16 8a8 8 0 1 1 0 16 8 8 0 0 1 0-16zm1 11h-2v2h2v-2zm-.956-7c-1.364 0-2.311.553-3.044 1.365l.947.983c.607-.627 1.264-.971 2.06-.971.909 0 1.452.48 1.452 1.168v.025c0 .811-.733 1.315-2.337 1.377l-.063.061.253 1.992h1.023l.126-.996C17.863 16.77 19 16.07 19 14.52v-.024C19 12.959 17.813 12 16.044 12z"/>
  </svg>
);

CircularQuestionIcon.defaultProps = {
  width: 32,
  height: 32,
  viewBox: `0 0 32 32`,
};

export default CircularQuestionIcon;

