import React from 'react';
import PropTypes from 'prop-types';
import LoadingIcon from '../ui-kit/Icons/LoadingIcon';

const style = (type, width, disabled) =>
  `text-teal focus:outline-none py-8 ${
    disabled
      ? 'cursor-not-allowed text-light-indicator-grey'
      : 'hover:text-teal-dark active:text-teal-light'
  } focus:outline-none font-MarkProBold text-xs os-transition-btn`;

  /**
 * Link Button component
 */
const LinkButton = ({
  type,
  text,
  width,
  handler,
  leftAlign,
  disabled,
  className,
  loading,
}) => (
  <button
    type={type}
    disabled={disabled}
    style={!isNaN(width) ? { width: `${width}rem` } : null}
    className={`${style(type, width, disabled)} ${className}`}
    onClick={!disabled && !loading ? handler : null}
  >
    {loading ? (
      <LoadingIcon />
    ) : (
      <span>
        {text && (
          <span className={leftAlign ? 'btn-text mr-auto' : 'btn-text'}>
            {text}
          </span>
        )}
      </span>
    )}
  </button>
);

LinkButton.propTypes = {
  /** Button text */
  text: PropTypes.string,
  /** Width supports a Tailwind width class passed on as string or a fixed width passed on as number in rem.**/
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Handler function for this button. This function will be invoked with the click event received on the button */
  handler: PropTypes.func,
  /** Whether the button text is left aligned. Defaults to false.**/
  leftAlign: PropTypes.bool,
  /** Whether the button is disabled or not. Defaults to false.**/
  disabled: PropTypes.bool,
  /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
  className: PropTypes.string,
  /* Used to display loading icon */
  loading: PropTypes.bool,
  /* Type style treatment */
  type: PropTypes.string,
};

LinkButton.defaultProps = {
  text: '',
  width: 'auto',
  leftAlign: false,
  disabled: false,
  className: '',
  loading: false,
};

export default LinkButton;
