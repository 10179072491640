import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormSection from './ui-kit/FormSection';
import Input from './ui-kit/Input';
import Button from './ui-kit/Button';
import CheckBox from './ui-kit/CheckBox';
import Label from './ui-kit/Label';
import _ from 'lodash';
import Message from './ui-kit/Message';
import moment from 'moment';
import defaultConfig from '../../constants/defaultConfig';
import {
  getFormSectionProps,
  isCurrentStage,
  completeCurrentStage,
} from '../../ducks/stepper';
import { isLoggedIn, createAccount, legacyCreateUser } from '../../ducks/user';
import { loginUser } from '../../actions/user';
import DatePicker from './DatePicker';

const { FEATURE_PHONE_CODE_VERIFICATION } = defaultConfig.features;

class CreateAccount extends Component {
  state = {
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    checked: false,
    validPassword: false,
    validFirstName: false,
    validLastName: false,
    inProgress: false,
    isSuccess: false,
    showFailure: false,
    allFieldsValid: false,
    subscriptionUserInProgress: false,
    updateVoucherSubmitButton: false,
    loggedIn: false,
    lockForm: false,
    dateOfBirthDay: null,
    dateOfBirthMonth: null,
    dateOfBirthYear: null,
    dateOfBirth: null,
    validDateOfBirthDay: false,
    validDateOfBirthMonth: false,
    validDateOfBirthYear: false,
    validDateOfBirth: false,
    phoneNumber: null,
    validPhoneNumber: false,
  };

  componentDidMount() {
    const { onLoad } = this.props;
    if (onLoad) {
      onLoad();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { account } = this.props;
    const { created: prevCreated } = prevProps.account;
    const { created } = account;
    const hasTransitionedToCreated = !prevCreated && created;
    if (hasTransitionedToCreated) {
      this.props.completeCurrentStage();
      this.loginUser();
      return;
    }
    const { showFailure } = this.state;
    const { error: prevAccountError } = prevProps.account;
    const { error: currAccountError } = account;
    const hasTransitionedToAccountError = !prevAccountError && currAccountError;
    if (!showFailure && hasTransitionedToAccountError) {
      this.setState({
        showFailure: true,
        inProgress: false,
        lockForm: false,
      });
      return;
    }
    const { inProgress: prevInProgress } = prevState;
    const { inProgress: currInProgress } = this.state;
    const hasTransitionedToInProgress = !prevInProgress && currInProgress;
    if (hasTransitionedToInProgress) {
      this.setState({ showFailure: false });
      // TODO: Decouple voucher related stuff
      const updateVoucherSubmitButton = _.get(
        this.props.user,
        'createUserByVoucher.updateVoucherSubmitButton',
      );
      const handler = updateVoucherSubmitButton
        ? this.onRetrySubmit
        : this.onSubmit;
      handler();
      return;
    }
    const { error: prevError } = prevProps.voucher;
    const { error: currError } = this.props.voucher;
    const { loginFailure } = this.props.user;
    const hasTransitionedToError = !prevError && (currError || loginFailure);

    const { stepper, stage } = this.props;
    if (
      isCurrentStage(stepper, stage) &&
      !showFailure &&
      hasTransitionedToError
    ) {
      // TODO: Refactor error handling to decouple for voucher
      this.setState({ showFailure: true, inProgress: false });
      // Copied from previous logic.
      // TODO: Decouple voucher related stuff
      const updateVoucherSubmitButton = _.get(
        this.props.user,
        'createUserByVoucher.updateVoucherSubmitButton',
      );
      if (updateVoucherSubmitButton) {
        this.setState({
          updateVoucherSubmitButton,
          lockForm: true,
        });
      }
    }
  }

  firstNameChange = (value) => {
    this.setState({ firstName: value });
  };

  lastNameChange = (value) => {
    this.setState({ lastName: value });
  };

  validateName(name) {
    const validNamelReg = /^[a-z-]+( [a-z-]+)*$/i;
    return (
      typeof name === 'string' && validNamelReg.test(name) && name.length <= 40
    );
  }

  validateFirstName(firstName) {
    if (!_.isEmpty(firstName) && this.validateName(firstName)) {
      this.setState({ validFirstName: true }, this.allFieldsAreValidated);
      return true;
    }
    this.setState({ validFirstName: false }, this.allFieldsAreValidated);
    return false;
  }

  validateLastName(lastName) {
    if (!_.isEmpty(lastName) && this.validateName(lastName)) {
      this.setState({ validLastName: true }, this.allFieldsAreValidated);
      return true;
    }
    this.setState({ validLastName: false }, this.allFieldsAreValidated);
    return false;
  }

  dateOfBirthDayChange = (value) => {
    if (_.isEmpty(value) || !this.validateDateOfBirthDay(value)) {
      return false;
    } else {
      this.setState({ dateOfBirthDay: value });
    }
  };

  validateDateOfBirthDay = (dateOfBirthDay) => {
    const validDayReg = /^([-0-9]+)$/i;
    const day = Number(dateOfBirthDay);
    const validDay =
      typeof dateOfBirthDay === 'string' &&
      validDayReg.test(dateOfBirthDay) &&
      dateOfBirthDay.length <= 2 &&
      day > 0 &&
      day < 32;

    if (!_.isEmpty(dateOfBirthDay) && validDay) {
      this.setState({ validDateOfBirthDay: true }, this.dateOfBirthValidated);
      return true;
    }
    this.setState({ validDateOfBirthDay: false }, this.dateOfBirthValidated);
    return false;
  };

  dateOfBirthMonthChange = (value) => {
    if (_.isEmpty(value) || !this.validateDateOfBirthMonth(value)) {
      return false;
    } else {
      this.setState({ dateOfBirthMonth: value });
    }
  };

  validateDateOfBirthMonth = (dateOfBirthMonth) => {
    const validMonthReg = /^([-0-9]+)$/i;
    const month = Number(dateOfBirthMonth);
    const validMonth =
      typeof dateOfBirthMonth === 'string' &&
      validMonthReg.test(dateOfBirthMonth) &&
      dateOfBirthMonth.length <= 2 &&
      month > 0 &&
      month < 13;

    if (!_.isEmpty(dateOfBirthMonth) && validMonth) {
      this.setState({ validDateOfBirthMonth: true }, this.dateOfBirthValidated);
      return true;
    }
    this.setState({ validDateOfBirthMonth: false }, this.dateOfBirthValidated);
    return false;
  };

  dateOfBirthYearChange = (value) => {
    if (_.isEmpty(value) || !this.validateDateOfBirthYear(value)) {
      return false;
    } else {
      this.setState({ dateOfBirthYear: value });
    }
  };

  validateDateOfBirthYear = (dateOfBirthYear) => {
    const validYearReg = /^([-0-9]+)$/i;
    const year = Number(dateOfBirthYear);
    const currentYear = moment().year();
    const isValidFourDigitYear =
      dateOfBirthYear.length === 4 && year > 1900 && year < currentYear;
    const validYear =
      typeof dateOfBirthYear === 'string' &&
      validYearReg.test(dateOfBirthYear) &&
      isValidFourDigitYear;

    if (!_.isEmpty(dateOfBirthYear) && validYear) {
      this.setState({ validDateOfBirthYear: true }, this.dateOfBirthValidated);
      return true;
    }
    this.setState({ validDateOfBirthYear: false }, this.dateOfBirthValidated);
    return false;
  };

  dateOfBirthValidated = () => {
    const {
      dateOfBirthDay,
      dateOfBirthMonth,
      dateOfBirthYear,
      validDateOfBirthDay,
      validDateOfBirthMonth,
      validDateOfBirthYear,
    } = this.state;
    if (validDateOfBirthDay && validDateOfBirthMonth && validDateOfBirthYear) {
      const isValid =
        moment(
          `${dateOfBirthYear}-${dateOfBirthMonth}-${dateOfBirthDay}`,
        ).format() !== 'Invalid date';
      if (isValid) {
        const dateOfBirth = new Date(
          `${dateOfBirthYear}-${dateOfBirthMonth}-${dateOfBirthDay}`,
        ).toISOString();
        this.setState(
          {
            validDateOfBirth: true,
            dateOfBirth,
          },
          this.allFieldsAreValidated,
        );
      } else {
        this.setState({ validDateOfBirth: false }, this.allFieldsAreValidated);
      }
    } else {
      this.setState({ validDateOfBirth: false }, this.allFieldsAreValidated);
    }
  };

  phoneNumberChange = (value) => {
    if (_.isEmpty(value) || !this.validatePhoneNumber(value)) {
      return false;
    } else {
      this.setState({ phoneNumber: value });
    }
  };

  dateOfBirthChange = (value) => {
    this.setState({ dateOfBirth: value });
  };

  validatePhoneNumber = (phoneNumber) => {
    const validPhoneNumberReg = /^[0][4]\d{8}$|^[1-9]\d{8}$/;
    const validPhoneNumber =
      typeof phoneNumber === 'string' && validPhoneNumberReg.test(phoneNumber);

    if (!_.isEmpty(phoneNumber) && validPhoneNumber) {
      this.setState({ validPhoneNumber: true });
      return true;
    }
    this.setState({ validPhoneNumber: false });
    return false;
  };

  allFieldsAreValidated = () => {
    const {
      checked,
      validFirstName,
      validLastName,
      validPassword,
      validPhoneNumber,
    } = this.state;
    const isPhoneNumberValid = FEATURE_PHONE_CODE_VERIFICATION
      ? true
      : validPhoneNumber;
    if (
      checked &&
      validFirstName &&
      validLastName &&
      validPassword &&
      isPhoneNumberValid
    ) {
      this.setState({ allFieldsValid: true });
    } else {
      this.setState({ allFieldsValid: false });
    }
  };

  passwordChange = (value) => {
    this.setState({ password: value });
  };

  validatePassword(password) {
    const validPasswordReg = /(?=[a-zA-Z0-9!@#$%^&*()-_=+<>?[\]{}|~`';":,.]*$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}/;
    if (
      !_.isEmpty(password) &&
      typeof password === 'string' &&
      validPasswordReg.test(password)
    ) {
      this.setState({ validPassword: true }, this.allFieldsAreValidated);
      return true;
    }
    this.setState({ validPassword: false }, this.allFieldsAreValidated);
    const { onInvalidPassword } = this.props;
    if (onInvalidPassword) {
      onInvalidPassword();
    }
    return false;
  }

  handleOnCheck = (checked) => {
    this.setState({ checked }, this.allFieldsAreValidated);
  };

  createUser = () => {
    const {
      password,
      firstName,
      lastName,
      dateOfBirth,
      phoneNumber,
    } = this.state;
    const { stepper, verification } = this.props;
    const { token } = verification;
    const { data } = stepper;
    const { email, number } = data;
    const userName = email;
    const mobilePhoneNumber = FEATURE_PHONE_CODE_VERIFICATION
      ? number
      : phoneNumber;
    const userInfo = {
      email: userName,
      userName,
      password,
      firstName,
      lastName,
      mobileNumber: mobilePhoneNumber,
      dateOfBirth,
      token,
    };
    if (FEATURE_PHONE_CODE_VERIFICATION) {
      this.props.createAccount(userInfo);
      return;
    }
    if (this.props.onLegacyCreateUser) {
      this.props.onLegacyCreateUser(userInfo);
      return;
    }
    this.props.legacyCreateUser(userInfo);
  };

  loginUser = () => {
    const { password } = this.state;
    const { stepper } = this.props;
    const {
      data: { email },
    } = stepper;
    const userName = email;
    this.props.loginUser({
      email: userName,
      password: password,
    });
  };

  onSubmit = () => {
    this.setState({ lockForm: true }, () => this.createUser());
    this.triggerGAE();
  };

  onRetrySubmit = () => {
    this.setState({ lockForm: true }, () => this.loginUser());
    this.triggerGAE();
  };

  triggerGAE = () => {
    const { onSubmit } = this.props;
    if (onSubmit) {
      const allFieldsValid = _.get(this.state, 'allFieldsValid');
      const validPassword = _.get(this.state, 'validPassword');
      const validPhoneNumber = FEATURE_PHONE_CODE_VERIFICATION
        ? true
        : _.get(this.state, 'validPhoneNumber');
      const checked = _.get(this.state, 'checked');
      onSubmit({
        allFieldsValid,
        validPassword,
        validPhoneNumber,
        checked,
      });
    }
  };

  // This is necessary due to Chrome's autofill behaviour
  renderAutoFillBuster() {
    return (
      <Input className={`hidden`} type='password' name='hidden' value='1234' />
    );
  }

  render() {
    const {
      firstName,
      lastName,
      checked,
      inProgress,
      showFailure,
      allFieldsValid,
      subscriptionUserInProgress,
      lockForm,
      phoneNumber,
    } = this.state;

    const { stepper, user, stage, showFields = [], type = '' } = this.props;
    const { data } = stepper;
    const { email, number, nationalNumber } = data;
    const phoneProps = FEATURE_PHONE_CODE_VERIFICATION && {
      showLockedIcon: true,
      id: 'phone-number-locked',
      disabled: true,
      value: nationalNumber || number,
      type: `tel`,
    };

    const shouldRender = !isLoggedIn(user);
    const formSectionProps = getFormSectionProps(stepper, stage);
    return (
      shouldRender && (
        <FormSection label={this.props.label} {...formSectionProps}>
          <div className='w-full flex'>
            {this.renderAutoFillBuster()}
            <Input
              show={showFields.includes(`firstName`)}
              type='string'
              id='user-first-name'
              name='firstName'
              ref={(firstNameEl) => {
                this.firstNameEl = firstNameEl;
              }}
              labelText='First name *'
              disabled={lockForm}
              placeHolder='e.g John'
              width='w-1/2'
              value={firstName}
              changed={this.firstNameChange}
              validate={true}
              showIcon={lockForm ? false : true}
              validator={(value, e) => {
                return !this.validateFirstName(value)
                  ? 'Invalid first name provided.'
                  : null;
              }}
            />
            <Input
              show={showFields.includes(`lastName`)}
              type='string'
              id='user-last-name'
              name='lastName'
              ref={(lastNameEl) => {
                this.lastNameEl = lastNameEl;
              }}
              className='ml-16 xl:ml-48'
              labelText='Last name *'
              disabled={lockForm}
              placeHolder='e.g Smith'
              width='w-1/2'
              value={lastName}
              changed={this.lastNameChange}
              validate={true}
              showIcon={lockForm ? false : true}
              validator={(value, e) => {
                return !this.validateLastName(value)
                  ? 'Invalid last name provided.'
                  : null;
              }}
            />
          </div>
          <div className='leading-1.14 mb-8'>
            <div className='w-full flex'>
              {this.renderAutoFillBuster()}
              <Input
                show={
                  FEATURE_PHONE_CODE_VERIFICATION
                    ? showFields.includes(`phoneNumber`)
                    : true
                }
                type='number'
                id='phone-number'
                name='phoneNumber'
                ref={(phoneNumber) => {
                  this.phoneNumber = phoneNumber;
                }}
                labelText='Mobile Phone *'
                disabled={lockForm}
                placeHolder='0400123456'
                width='w-full md:w-1/2'
                maxLength={10}
                value={phoneNumber}
                changed={this.phoneNumberChange}
                validate
                showIcon={lockForm ? false : true}
                validator={(value, e) => {
                  return !this.validatePhoneNumber(value)
                    ? 'This isn’t a valid Australian mobile number.'
                    : null;
                }}
                marginBottom='8'
                disableSpinButton
                {...phoneProps}
              />
            </div>
          </div>
          {this.renderAutoFillBuster()}
          <Input
            show={showFields.includes(`email`)}
            className='leading-1.14 mb-8 md:w-360 xl:w-381'
            type='string'
            id='user-email-locked'
            name='email'
            ref={(emailEl) => {
              this.emailEl = emailEl;
            }}
            labelText='Email address *'
            disabled={true}
            placeHolder='john@email.com'
            value={email}
            showLockedIcon={true}
          />
          {this.renderAutoFillBuster()}
          <DatePicker
            show={showFields.includes(`dateOfBirth`)}
            id='user-dateOfBirth'
            name='dateOfBirth'
            labelText='Date of birth'
            placeHolder='Select your birth date'
            showIcon
            disabled={lockForm}
            showLockedIcon={lockForm}
            onChange={this.dateOfBirthChange}
          />
          <div onClick={(e) => e.stopPropagation()}>
            {this.renderAutoFillBuster()}
            <Input
              show={showFields.includes(`password`)}
              type='password'
              id='user-password'
              name='password'
              ref={(passwordEl) => {
                this.passwordEl = passwordEl;
              }}
              className='leading-1.14 md:w-360 xl:w-381'
              labelText='Password *'
              disabled={lockForm}
              placeHolder='Choose a strong password'
              changed={this.passwordChange}
              showIcon={lockForm ? false : true}
              validate={true}
              validator={(value, e) => {
                return !this.validatePassword(value)
                  ? 'Password is not valid.'
                  : null;
              }}
              marginBottom='8'
            />
            <div className='md:w-353'>
              <Label textSize='text-xs leading-loose font-MarkProBold text-light-grey'>
                Password must be 8 characters long and include one number and
                one capital letter.
              </Label>
            </div>
            <div className='md:w-353'>
            <CheckBox
              name='acceptTerms'
              className='mt-24'
              checked={checked}
              changed={this.handleOnCheck}
              id='accept-terms'
            >
              <Label className='text-sm leading-normal' htmlFor='accept-terms'>
                {`I accept the `}
                <a
                  href='https://sport.optus.com.au/terms'
                  target='_blank'
                  className='text-teal no-underline hover:text-teal-dark active:text-teal-light os-transition-btn'
                  rel='noopener noreferrer'
                >
                  terms and conditions
                </a>
                {` and `}
                <a
                  href='https://optus.com.au/privacy'
                  target='_blank'
                  className='text-teal no-underline hover:text-teal-dark active:text-teal-light os-transition-btn'
                  rel='noopener noreferrer'
                >
                  privacy policy
                </a>
                *
              </Label>
            </CheckBox>
            </div>
            <div className='md:w-353 pt-32'>
              <Label textSize='text-xs leading-loose font-MarkPro text-light-grey'>
                Your personal information may be used by Optus to send you
                marketing material we think you will be interested in from time
                to time. For more information on how we handle your personal
                information please visit{' '}
                <a
                  href='https://optus.com.au/privacy'
                  target='_blank'
                  className='text-teal no-underline hover:text-teal-dark active:text-teal-light os-transition-btn'
                  rel='noopener noreferrer'
                >
                  optus.com.au/privacy
                </a>
                .
              </Label>
            </div>
            <div className='mt-10 mb-10'>
              {showFailure && (
                <Message
                  type='failure'
                  className='mt-8 mb-16'
                  message={`Sorry, we encountered an error${
                    type === 'basic-signup'
                      ? ''
                      : ' and the code was not redeemed'
                  }. Please try again. If the error persists, please contact Optus Sport support.`}
                  open={true}
                />
              )}
            </div>
            <div
              className={`${
                allFieldsValid ? 'pointer-events-auto' : 'pointer-events-none'
              }`}
            >
              <Button
                text='Submit'
                type='primary'
                loading={inProgress || subscriptionUserInProgress}
                handler={() => this.setState({ inProgress: true })}
                width='w-full mt-24 md:w-266 xl:w-276'
                rounded
                disabled={!allFieldsValid}
              />
            </div>
          </div>
        </FormSection>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    voucher: state.voucher,
    user: state.user,
    verification: state.verification,
    account: state.account,
    stepper: state.stepper,
  };
};

const mapDispatchToProps = {
  completeCurrentStage,
  createAccount,
  legacyCreateUser,
  loginUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
