import _ from 'lodash';

export function isEmptyJumboTron(getFeatureFlags, user, path, panels) {
  const fitnessConfig = getFeatureFlags('FITNESS_CONFIG');
  const fitnessPaywallEnabled = _.get(fitnessConfig, 'paywallEnabled');
  const userInitObserver = user?.initObserver;
  const userLoggedIn = _.get(user, 'loggedIn');
  return (
    !_.find(panels, { panelType: 'mainCarousel' }) &&
    userInitObserver &&
    userLoggedIn &&
    (path !== 'fitness' || !fitnessPaywallEnabled)
  );
}
